import { Component, OnInit, Input, ViewEncapsulation, OnDestroy,ChangeDetectorRef  } from '@angular/core';
import { Observable, Subscription, Subject, takeUntil, catchError, of } from 'rxjs';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorFn, FormBuilder, FormGroup, Validators, FormGroupName, FormControl, FormArray } from '@angular/forms';
import { BrandsList } from '../../../shared/data/tables/brands-list';
import {IDraw} from '../../../shared/data/tables/draw';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {BrandService} from '../../../shared/services/brand.service';
import {FileService} from '../../../shared/services/file.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UploadWidgetConfig, UploadWidgetResult, UploadWidgetOnUpdateEvent } from '@bytescale/upload-widget';
import {ProjectService} from '../../../shared/services/project.service';
import {DrawService} from '../../../shared/services/draw.service';
import {Router, ActivatedRoute} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ColorService } from '../../../shared/services/theme.service';
@Component({
  selector: 'app-draw-modal',
  templateUrl: './draw-modal.component.html',
  styleUrls: ['./draw-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrawModalContent implements OnInit,OnDestroy{ 
  isLoading = false;
  smallestValue: number | null = null;
  buttonWarning: boolean = false;
  isUpdateMode: boolean = false; 
  poolConsumerData: number ; 
  poolEntryData: number ; 
  isEditMode: boolean = false; 
  drawId: string | null = null; 
  formDisabled: boolean = false;
  showEditAndDraws: boolean = false; 
  isSubmitting: boolean = false;
  showDemographicTable = false;
  showMetadataTable = false;
  hasDemographicValues = false;
  hasMetadataValues = false;
  demographicValuesMap: { [key: string]: string[] } = {};
  metadataValuesMap: { [key: string]: string[] } = {};
  @Input() data;
  @Input() id;
  @Input() projdate;
  drawTypes$: Observable<any>;
  brandData;
  drawForm;
  submitted = false;
  _uploadedFile = undefined;
  model: NgbDateStruct;
  date: { year: number, month: number };
  modelPopup
  modelDisabled: NgbDateStruct;
  disabled = true;
  modelCustom: NgbDateStruct;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  hoveredDate: NgbDate | any;
  fromDate: NgbDate;
  toDate: NgbDate | any;
  modelFooter: NgbDateStruct;
  today = this.calendar.getToday();
  profileProperties: string[] = [];
  selectedProperty: string;
  selectedMetaData: string;
  propertyValues: string[] = [];
  metaValues: string[] = [];
key: any
  destroy$: Subject<boolean> = new Subject()
  project$: Observable<any | null>;
  projectItem$: Subscription;
  projectLoading$: Observable<boolean>;
  error$:Observable<any>;
  projectDrawType$:  Observable<any | null>;
  drawTypeLoading$: Observable<boolean>;
  performingDraw$:Observable<any>;
  metadataFields: string[] = [];
  selectedField: string;
  metadataValues: string[] = [];
  draw$:  Observable<any | null>;
  drawSubmitting$: Observable<boolean>;
  queue$: Observable<boolean>;
  showAdvancedFilters = false; 
  colorScheme: string;
  filters: any[] = [];
  metadataFilters: any[] = [{}];
  get configuration(){return (this.drawForm.get('configuration') as FormGroup)}

  private paging = {
take:500,
skip:0
  }
  ngOnInit() {

    if(this.projdate) {
      console.log(this.projdate)
    }
      if(this.id) this.projectService.getDrawType(this.id);
  
      this.draw$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if(!res) return;
        this.submitted = false;
        // this.drawService.perform(res.id, this.id);
      })
  
      // this.performingDraw$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      //   if(!res) return;      
      //   // this.toastrService.success('Draw performed', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
  
      //   // this.generateExport(res);
      //   // this.activeModal.close();
        
      // })
  
      this.queue$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if(!res) return;
        this.toastrService.success('Export Queued', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
        return 
      })
  
      this.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
        if(!err) return;
        this.toastrService.error(err, undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
      })
  
      this.initForm(null)
      // this.addInitialFilter();
      // this.addInitialFilters();
      // DRAWS
      this.fetchProfileProperties();
      this.fetchMetaData();
  
      this.drawTypes$ = this.drawService.fetchDrawTypes();
      this.drawTypes$.subscribe(
        data => {
     
        },
        error => {
          this.toastrService.error('Failed to load draw types', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
        }
      );
  
      if (this.id) {
        this.drawTypes$ = this.drawService.fetchDrawTypesID(this.id);
        this.drawTypes$.subscribe(
            data => {
                // console.log('Draw Types:', data);
            },
            error => {
                this.toastrService.error('Failed to load draw types', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
            }
        );
    }
  
    
    this.projectService.getProfileProperties(this.id).subscribe({
      next: (data) => {
        this.profileProperties = data;
      },
      error: (error) => {
        // console.error('There was an error!', error);
      }
    });
  
    this.projectService.getMetadataFields(this.id).subscribe({
      next: (fields) => {
        this.metadataFields = fields;
        // console.log('FIELDS',fields)
      },
      error: (error) => console.error('Failed to fetch metadata fields', error)
    });
    // console.log('this.demographicFilters.controls',this.demographicFilters.controls);
    // console.log('this.entryMetadataFilters.controls',this.entryMetadataFilters.controls);
    this.checkAdvancedFilters();
    this.getProjectDetails(this.id);
  
    // population
    if (this.data) {
      this.isUpdateMode = true; 
      this.drawId = this.data.id; 
      if (this.drawId) {
        // this.getPoolSize(this.drawId); // Only call getPoolSize if drawId is not null
      }
      this.drawForm.patchValue({
        id: this.data.id,
        name: this.data.name,
        drawType: this.data.drawType,
        configuration: {
          numberOfEntries: this.data.configuration.numberOfEntries,
          entriesFrom: new Date(this.data.configuration.entriesFrom),
          entriesUntil: new Date(this.data.configuration.entriesUntil),
          // Populate any demographic filters and metadata if necessary
          demographicFilters: this.data.configuration.demographicFilters || [],
          entryMetadataFilters: this.data.configuration.entryMetadataFilters || [],
        }
      });
  
      this.startDate.setValue({
        year: new Date(this.data.configuration.entriesFrom).getFullYear(),
        month: new Date(this.data.configuration.entriesFrom).getMonth() + 1,
        day: new Date(this.data.configuration.entriesFrom).getDate()
      });
  
      this.endDate.setValue({
        year: new Date(this.data.configuration.entriesUntil).getFullYear(),
        month: new Date(this.data.configuration.entriesUntil).getMonth() + 1,
        day: new Date(this.data.configuration.entriesUntil).getDate()
      });
  
      this.populateFilters();
    } else {
      this.initForm(null);
    }

    if (this.isUpdateMode && this.data) {
      this.drawId = this.data.id;
      this.initializeFormWithExistingData(this.data);
      this.updateAdvancedFiltersVisibility();
      if (this.drawId) {
        // this.getPoolSize(this.drawId); // Only call getPoolSize if drawId is not null
      }
    } else {
      this.initForm(); 
    }

    
    }
  

    initializeFormWithExistingData(data: any) {
      this.drawForm.patchValue({
        id: this.data.id,
        name: this.data.name,
        drawType: this.data.drawType,
        configuration: {
          numberOfEntries: this.data.configuration.numberOfEntries,
          entriesFrom: new Date(this.data.configuration.entriesFrom),
          entriesUntil: new Date(this.data.configuration.entriesUntil),
          // Populate any demographic filters and metadata if necessary
          demographicFilters: this.data.configuration.demographicFilters || [],
          entryMetadataFilters: this.data.configuration.entryMetadataFilters || [],
        }
      });
      // Add additional setup as necessary
    }

  // private generateExport(draw){
  //   let _config = {
  //     "type": "draws",
  //     "configuration": draw ? {draw: draw.id} : "",
  //     "name": draw ? draw.name : "All draws",
  //     "description": ""
  //   }
  //   this.projectService.export(_config,this.id);
  //   //this.drawService.queue(draw.id);
  // }

  constructor(config: NgbModalConfig,               
              private formBuilder: FormBuilder,  
              private brandService:BrandService,  
              public activeModal: NgbActiveModal,
              private toastrService: ToastrService,   
              private calendar: NgbCalendar,
              private projectService: ProjectService,
              private drawService:DrawService,
              private route:ActivatedRoute,
              private http: HttpClient,
              private cdr: ChangeDetectorRef,
              private colorService: ColorService
              ) {
                this.projectDrawType$ = this.projectService.projectDrawType$; 
                this.drawTypeLoading$ = this.projectService.drawTypeLoading$;
                this.draw$ = this.projectService.draw$; 
                this.drawSubmitting$ = this.projectService.drawSubmitting$;
                this.error$ = this.projectService.error$;
                this.performingDraw$ = this.drawService.performingDraw$;
                this.queue$ = this.drawService.queue$;
              }

  private initForm(data?){
    this.drawForm = this.formBuilder.group({
      id: [data? data.id: ''],
      name: [ data? data.name : '', [Validators.required, Validators.minLength(3)]],           
      description: [data? data.description : ''],
      drawType: [data? data.drawType : '',[Validators.required]],
      configuration: this.formBuilder.group({
        // numberOfEntries:[data? data.description : '',[Validators.required, Validators.min(1)]],
        numberOfEntries: [1, [Validators.required, Validators.min(1)]], 
        entriesFrom: [Date.parse(this.projdate?.configuration.dashboard.reportsStartAt) ?? new Date()],
        entriesUntil: [Date.parse(this.projdate?.configuration.dashboard.reportsEndAt) ?? new Date()],
        demographicFilters: this.formBuilder.array([]),
        entryMetadataFilters: this.formBuilder.array([]),
        key:[]
      })
    });
    
    if (!this.demographicFilters.length) {
      // this.addDemographicFilter();
    }
    if (!this.metadataFilters.length) {
      this.addMetadataFilter();
    }

     if(this.projdate){
      let _start = new Date(this.projdate?.configuration.dashboard.reportsStartAt);
      let _end = new Date(this.projdate?.configuration.dashboard.reportsEndAt);
    
      this.startDate.setValue({year:_start.getFullYear(), month:_start.getMonth() + 1, day:_start.getDate()})
      this.endDate.setValue({year:_end.getFullYear(), month:_end.getMonth() + 1, day:_end.getDate()})
    }
  }

  checkAdvancedFilters() {
    const demographicFilters = this.data?.configuration?.demographicFilters || [];
    const metadataFilters = this.data?.configuration?.entryMetadataFilters || [];
  
    if (demographicFilters.length > 0 || metadataFilters.length > 0) {
      this.showAdvancedFilters = true;
    }
  }


  populateFilters() {
    // Populate demographic filters
    if (this.data.configuration.demographicFilters && this.data.configuration.demographicFilters.length > 0) {
      this.data.configuration.demographicFilters.forEach((filter) => {
        // console.log('Demographic Filter Key:', filter.key);
        // console.log('Demographic Filter Values:', filter.values);
        const filterGroup = this.formBuilder.group({
          key: [filter.key, Validators.required],
          values: this.formBuilder.array(filter.values.map(value => this.formBuilder.control(value)))
        });
        this.demographicFilters.push(filterGroup);
      });
      this.showDemographicTable = true;
    }
  
    // Populate metadata filters
    if (this.data.configuration.entryMetadataFilters && this.data.configuration.entryMetadataFilters.length > 0) {
      this.data.configuration.entryMetadataFilters.forEach((filter) => {
              console.log('Metadata Filter Key:', filter.key); // Log individual filter key
      console.log('Metadata Filter Values:', filter.values);
        const filterGroup = this.formBuilder.group({
          key: [filter.key, Validators.required],
          values: this.formBuilder.array(filter.values.map(value => this.formBuilder.control(value)))
        });
        this.entryMetadataFilters.push(filterGroup);
      });
      this.showMetadataTable = true;
    }
  }


  onSubmit(){
    this.submitted = true;
    this. cleanupFilters()
    this.dateChange();

    if(!this.drawForm.valid) return;  
    this.submitted = false;
    if (this.drawForm.invalid) {
      return; 
    }
    this.isSubmitting = true; 
    if (this.drawForm.valid) {
      this.isSubmitting = true; 
      this.projectService.newDraw(this.drawForm.value, this.id).subscribe({
          next: (result) => {
            this.drawId = result.id; 
            if (this.drawId) {
              this.getPoolSize(this.drawId);
          } else {
              // console.error('Draw ID is null, cannot fetch pool size.');
          } 
            console.log(this.drawId,'Draw ID')
              this.toastrService.success('Draw saved successfully!');
              this.isSubmitting = false;
              this.formDisabled = true; 
              this.drawForm.disable();
              this.showEditAndDraws = true;

          },
          error: (error) => {
              this.toastrService.error('Error saving the draw');
              this.isSubmitting = false; 
              // console.error('Save failed', error);
          }
      });
  } else {
      this.toastrService.error('Please check the form for errors.');
  }
    
    // return this.save();     
  }
  onFormSubmit() {
    if (this.isEditMode || this.isUpdateMode) {
        this.onUpdate();
    } else {
        this.onSubmit(); 
       
    }
    // this.updateDrawButton()
}

performDraw() {
  this.isSubmitting = true; 
  // console.log('Performing draw with ID:', this.drawId);

  if (!this.drawId) {
    // console.log('No drawId is set, cannot perform draw.');
    return;
  }

  // console.log('Calling perform service with drawId:', this.drawId, 'and projectId:', this.id);

  this.drawService.perform(this.drawId, this.id).subscribe({
    next: () => {
      this.isSubmitting = false; 
      this.toastrService.success('Draw performed successfully.', 'Success');
      this.activeModal.close(true);
    },
    error: (error) => {
      this.isSubmitting = false; 
      // console.error('Failed to perform draw:', error);
      this.toastrService.error('Failed to perform the draw.', 'Error');
    }
  });
}


  onUpdate() {
    if (this.drawId) {
      this.getPoolSize(this.drawId);
    } else {
      this.toastrService.error('Draw ID is null, cannot fetch pool size!', 'Error');
      // console.error('Draw ID is null, cannot fetch pool size.');
    }
    this.isSubmitting = true; 
    this.submitted = true;
    if (!this.drawForm.valid || !this.drawId) return; 
    const updateUrl = `${environment.endpoints.api}draws/${this.drawId}`;  // Use drawId
    
    this.http.patch(updateUrl, this.drawForm.value).subscribe({
      next: (response: any) => {
        // console.log('Updated draw:', response);
        this.toastrService.success('Draw saved successfully.', 'Success');
        this.drawForm.disable();
        this.formDisabled = true;
        this.showEditAndDraws = true;
        this.isEditMode = false; 
        this.isSubmitting = false;  

      },
      error: error => {
        // console.error('Failed to update draw:', error);
        this.toastrService.error('Changes cannot be made after a draw has been performed!', 'Error');
        this.isSubmitting = false; 
      }
    });
  }

  getPoolSize(drawId: string) {
    this.isLoading = true;
    const url = `${environment.endpoints.api}draws/${drawId}/pool-size`;
    this.http.get<any>(url).subscribe({
      next: (data) => {
        // console.log('Pool Size Data:', data);
        this.poolConsumerData = data.consumers; 
        this.poolEntryData = data.entries
        this.updateDrawButtonSmallerValue();
        this.isLoading = false; 
        this.cdr.markForCheck(); 
      },
      error: error => {
        // console.error('Failed to fetch pool size:', error);
        this.toastrService.error('Failed to fetch pool size.', 'Error');
        this.isLoading = false; 
      }
    });
  } 
  updateDrawButtonSmallerValue() {
    const numberOfEntries = this.drawForm.get('configuration.numberOfEntries')?.value;
    const poolConsumerData = this.poolConsumerData;
    
    // console.log('Number of Entries from form:', numberOfEntries);
    // console.log('Pool Consumer Data:', poolConsumerData);
  
    const numberOfEntriesValue = numberOfEntries != null && !isNaN(numberOfEntries) ? parseInt(numberOfEntries, 10) : 0;
    const poolConsumerDataValue = poolConsumerData != null && !isNaN(poolConsumerData) ? poolConsumerData : 0;
  
    // console.log('Parsed Number of Entries:', numberOfEntriesValue);
    // console.log('Parsed Pool Consumer Data:', poolConsumerDataValue);
  
    if (numberOfEntriesValue < poolConsumerDataValue) {
      this.smallestValue = numberOfEntriesValue;
      this.buttonWarning = true;
    } else if (poolConsumerDataValue < numberOfEntriesValue) {
      this.smallestValue = poolConsumerDataValue;
      this.buttonWarning = true;
    } else {
      this.smallestValue = numberOfEntriesValue; 
      this.buttonWarning = false;
    }
  
    console.log('Smallest Value to Display:', this.smallestValue);
  
    this.cdr.detectChanges();
  }
  
  
  
  updateAdvancedFiltersVisibility() {
    const demographicFilters = this.drawForm.get('configuration.demographicFilters') as FormArray;
    const entryMetadataFilters = this.drawForm.get('configuration.entryMetadataFilters') as FormArray;
    
    this.showAdvancedFilters = demographicFilters.length > 0 || entryMetadataFilters.length > 0;
  
    const hasFilters = demographicFilters.controls.some(fg => fg.get('values')?.value.length > 0) ||
                       entryMetadataFilters.controls.some(fg => fg.get('values')?.value.length > 0);
  
    this.showAdvancedFilters = hasFilters;
  }
  
  

  private cleanupFilters() {
    // Clean up demographic filters
    const demographicFilters = this.demographicFilters;
    for (let i = demographicFilters.length - 1; i >= 0; i--) {
      const filterGroup = demographicFilters.at(i) as FormGroup;
      const key = filterGroup.get('key')?.value;
      const values = filterGroup.get('values')?.value;

      if (!key || !values || values.length === 0 || values.some(v => !v)) {
        demographicFilters.removeAt(i);
      }
    }

    // Clean up metadata filters
    const metadataFilters = this.entryMetadataFilters;
    for (let i = metadataFilters.length - 1; i >= 0; i--) {
      const filterGroup = metadataFilters.at(i) as FormGroup;
      const key = filterGroup.get('key')?.value;
      const values = filterGroup.get('values')?.value;

      if (!key || !values || values.length === 0 || values.some(v => !v)) {
        metadataFilters.removeAt(i);
      }
    }

    this.cdr.detectChanges();
  }
  

  
  get demographicFilters(): FormArray {
    return this.drawForm.get('configuration.demographicFilters') as FormArray;
  }
  

  get entryMetadataFilters(): FormArray {
    return this.drawForm.get('configuration.entryMetadataFilters') as FormArray;
  }
  
  dateChange(){
    this.configuration.controls['entriesFrom'].setErrors({'invalid': null})
    this.configuration.controls['entriesFrom'].updateValueAndValidity();
    this.configuration.controls['entriesUntil'].setErrors({'invalid': null})
    this.configuration.controls['entriesUntil'].updateValueAndValidity();

    let _sta:any = this.startDate?.value;
    let _start:any = `${_sta?.year}-${_sta?.month}-${_sta?.day}`;

    let _en:any = this.endDate?.value;
    let _end:any = `${_en?.year}-${_en?.month}-${_en?.day}`;

    this.configuration.controls['entriesFrom'].setValue(new Date(_start));
    this.configuration.controls['entriesUntil'].setValue(new Date(_end));

    if(!_sta) {this.configuration.controls['entriesFrom'].setErrors({'invalid': true})}
    if(!_en) {this.configuration.controls['entriesUntil'].setErrors({'invalid': true})}

    if(this.configuration.controls['entriesUntil'].value < this.configuration.controls['entriesFrom'].value){
      this.configuration.controls['entriesFrom'].setErrors({'invalid': true})
      this.configuration.controls['entriesUntil'].setErrors({'invalid': true})
        return this.toastrService.error('End date cannot be earlier than Start date.')
    } 
    return;
  }


  addDemographicFilterWithData(key: string, values: string[]): void {
    const valueArray = this.formBuilder.array(values.map(value => this.formBuilder.control(value)));
    const filterGroup = this.formBuilder.group({
      key: [key, Validators.required],
      value: valueArray
    });
    this.demographicFilters.push(filterGroup);    
  }

  addMetaDataFilterWithData(key: string, values: string[]): void {
    const valueArray = this.formBuilder.array(values.map(value => this.formBuilder.control(value)));
    const filterGroup = this.formBuilder.group({
      key: [key, Validators.required],
      value: valueArray
    });
    this.entryMetadataFilters.push(filterGroup);
  }

  // private addInitialFilter() {
  //   if (this.demographicFilters.length === 0) {
  //     this.addDemographicFilter(); 
  //   }
  // }
  // private addInitialFilters() {
  //   if (this.entryMetadataFilters.length === 0) {
  //     this.addMetadataFilter() 
  //   }
  // }
  
  addMetadataFilter(): void {
    const filterGroup = this.formBuilder.group({
      key: [''],
      values: this.formBuilder.array([])
    });
    this.entryMetadataFilters.push(filterGroup);
    this.showMetadataTable = true; 
    this.cdr.detectChanges(); 
  }
  
  addDemographicFilter(): void {
    const filterGroup = this.formBuilder.group({
      key: [''],
      values: this.formBuilder.array([])
    });
    this.demographicFilters.push(filterGroup);
    this.showDemographicTable = true;
    this.cdr.detectChanges(); 
  }


  ValuesChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValues = Array.from(selectElement.selectedOptions, (option: HTMLOptionElement) => option.value);

    const valuesFormArray = (this.entryMetadataFilters.at(index) as FormGroup).get('values') as FormArray;
    valuesFormArray.clear();
    selectedValues.forEach(value => {
      valuesFormArray.push(this.formBuilder.control(value));
    });

    this.cdr.detectChanges();
  }
  
  

  onValuesChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValues = Array.from(selectElement.selectedOptions, (option: HTMLOptionElement) => option.value);

    const valuesFormArray = (this.demographicFilters.at(index) as FormGroup).get('values') as FormArray;
    valuesFormArray.clear();
    selectedValues.forEach(value => {
      valuesFormArray.push(this.formBuilder.control(value));
    });

    this.cdr.detectChanges();
  }
  
  
  save(){
    this.projectService.newDraw(this.drawForm.value, this.id);
    this.submitted = false;
  }

  update(){
    this.brandService.update(this.drawForm.value);
    this.submitted = false;
  }

  onCancel(){
    this.drawForm.reset();
    this.activeModal.close(true)
  }

  startDate = new FormControl({}, (control: FormControl | any) => {
    const value = control.value;
    if (!value) return null;
    return null;
  });

  endDate = new FormControl({}, (control: FormControl | any) => {
    const value = control.value;
    if (!value) return null;
    return null;
  });

  isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  onDateSelection(date: NgbDate | any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate)
   {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate)
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date)
  }

  toggleAdvancedFilters(event: any) {
    this.showAdvancedFilters = event.target.checked;
  }

  addFilter(event: Event) {
    event.preventDefault(); 
    this.filters.push({});
  }


  // getProfileProperties(): Observable<string[]> {
  //   return this.http.get<string[]>(this.apiUrl);
  // }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchProfileProperties() {
    const url = `${environment.endpoints.api}projects/${this.id}/consumer/profile-properties`;
    this.http.get<string[]>(url).subscribe({
      next: (properties) => {
        this.profileProperties = properties;
        this.cdr.detectChanges();
      },
      error: (error) => {
        // console.error('Failed to fetch profile properties:', error);
        this.toastrService.error('Failed to load profile properties.', 'Error');
      }
    });
  }

  onPropertyChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    const key = selectElement.value;

    if (!key) return;

    const url = `${environment.endpoints.api}projects/${this.id}/consumer/profile-property/values?property=${key}`;
    this.http.get<string[]>(url).subscribe({
      next: (values) => {
        this.demographicValuesMap[key] = values;
        this.cdr.detectChanges();
      },
      error: (error) => {
        // console.error('Failed to fetch demographic values:', error);
        this.toastrService.error('Failed to load demographic values.', 'Error');
      }
    });
  }
  
    fetchMetaData() {
      const url = `${environment.endpoints.api}projects/${this.id}/entries/meta-data`;
      this.http.get<string[]>(url).subscribe({
        next: (fields) => {
          this.metadataFields = fields;
          this.cdr.detectChanges();
        },
        error: (error) => {
          // console.error('Failed to fetch metadata fields:', error);
          this.toastrService.error('Failed to load metadata fields.', 'Error');
        }
      });
  
  }

  onFieldSelect(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    const field = selectElement.value;

    if (!field) return;

    const url = `${environment.endpoints.api}projects/${this.id}/entries/meta-data/values?key=${field}`;
    this.http.get<string[]>(url).subscribe({
      next: (values) => {
        this.metadataValuesMap[field] = values;
        this.cdr.detectChanges();
      },
      error: (error) => {
        // console.error('Failed to fetch metadata values:', error);
        this.toastrService.error('Failed to load metadata values.', 'Error');
      }
    });
  }
  
  
  getMetadataValuesArray(index: number): FormArray {
    const filterGroup = this.entryMetadataFilters.at(index) as FormGroup;
    return filterGroup.get('values') as FormArray;
  }
  
  getMetadataValues(key: string, id: string): Observable<string[]> {
    const url = `${environment.endpoints.api}projects/${id}/entries/meta-data/values?key=${key}`;
    return this.http.get<string[]>(url);
  }

  // addFilterGroup(): void {
  //   const filterGroup = this.createFilterGroup();
  //   this.demographicFilters.push(filterGroup);
  // }

  private createFilterGroup(): FormGroup {
    return this.formBuilder.group({
      key: [''],  
      values: this.formBuilder.array([]) 
    });
  }

  getValuesArray(filterIndex: number): FormArray {
    const filterGroup = this.demographicFilters.at(filterIndex) as FormGroup;
    return filterGroup.get('values') as FormArray;
  }

  // color scheme
  getProjectDetails(id: string): void {
    this.colorService.getProjectById(id).subscribe(
      (projectDetails) => {
        // console.log('Project details:', projectDetails);
        this.colorScheme = projectDetails.colorScheme;
        // this.date = projectDetails;
      },
      (error) => {
        // console.error('Error fetching project details:', error);
      }
    );
  }

  removeDemographicFilter(index: number): void {
    const filters = this.demographicFilters;
    if (index > -1 && index < filters.length) {
        filters.removeAt(index);
    }
    if (filters.length === 0) {
        this.showDemographicTable = false;
    }
    this.cdr.detectChanges();
}

removeMetadataFiter(index: number): void {
    const filters = this.entryMetadataFilters;
    if (index > -1 && index < filters.length) {
        filters.removeAt(index);
    }
    if (filters.length === 0) {
        this.showMetadataTable = false;
    }
    this.cdr.detectChanges();
}


  canDelete(index: number): boolean {
    return index !== 0; 
  } 

  enableForm() {
    this.formDisabled = false;
    this.drawForm.enable();
    this.showEditAndDraws = false;
    this.isEditMode = true; // Enable edit mode

 }
}